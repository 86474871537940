/** @jsx jsx */
import React from "react"
import { jsx, Styled } from "theme-ui"
import styled from "styled-components"
import theme, { rem } from "@src/theme"
import { Link } from "gatsby"
import { Themed } from '@theme-ui/mdx'

const slugify = require("slugify")

const ProfileCard = ({ headshot, name, position, colorSet, staticCard }) => {
  function Inner () {
    return (
      <>
        <div
          sx={{
            height: rem(374),
          }}
        >
          <img src={headshot} className="object-fit" />
        </div>
        <div
          className="profile-content"
          sx={{
            color: colorSet.text,
            bg: colorSet.bg,
            p: rem(48),
            flex: 1,
          }}
        >
          <Themed.h3
            sx={{
              mt: 0,
              mb: rem(21),
            }}
          >
            {name}
          </Themed.h3>
          <Themed.h5
            sx={{
              m: 0,
            }}
          >
            {position}
          </Themed.h5>
        </div>
      </>
    )
  }

  return staticCard ? (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Inner />
    </div>
  ) : (
    <Outer
      to={`/people-and-stories/${slugify(name.toLowerCase())}`}
      className="unstyled-link"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Inner />
    </Outer>
  )
}

const Outer = styled(Link)`
  &:hover,
  &:active,
  &:focus {
    .profile-content {
      background-color: ${theme.colors.darkPurple};
      color: ${theme.colors.white};
      transition: background-color 300ms ease, color 300ms ease;
    }
  }

  h3 {
    text-decoration: underline;
  }
`

export default ProfileCard
